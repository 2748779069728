<template>
  <svg
    width="1600px"
    height="900px"
    viewBox="0 0 1600 900"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
    <title>4-clicks</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stop-color="#525252" offset="0%" />
        <stop stop-color="#1F1F1F" offset="100%" />
      </linearGradient>
      <rect id="path-2" x="0" y="0" width="42" height="42" rx="20.9999987" />
      <filter
        x="-13.1%"
        y="-10.7%"
        width="126.2%"
        height="126.2%"
        filterUnits="objectBoundingBox"
        id="filter-3"
      >
        <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
        <feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-4">
        <stop stop-color="#FFD200" offset="0%" />
        <stop stop-color="#937900" offset="100%" />
      </linearGradient>
      <rect id="path-5" x="0" y="0" width="42" height="42" rx="20.9999987" />
      <filter
        x="-13.1%"
        y="-10.7%"
        width="126.2%"
        height="126.2%"
        filterUnits="objectBoundingBox"
        id="filter-6"
      >
        <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
        <feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="path-7" x="0" y="0" width="42" height="42" rx="20.9999987" />
      <filter
        x="-13.1%"
        y="-10.7%"
        width="126.2%"
        height="126.2%"
        filterUnits="objectBoundingBox"
        id="filter-8"
      >
        <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
        <feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="path-9" x="0" y="0" width="42" height="42" rx="20.9999987" />
      <filter
        x="-13.1%"
        y="-10.7%"
        width="126.2%"
        height="126.2%"
        filterUnits="objectBoundingBox"
        id="filter-10"
      >
        <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
        <feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="4-clicks">
        <g
          id="Group"
          @click="onClickPin('tourB')"
          class="stadiumToursPin"
          :class="{'active': pinState.tourB}"
          transform="translate(108.000000, 506.000000)"
        >
          <g
            id="Rectangle"
            transform="translate(21.000000, 21.000000) scale(-1, 1) translate(-21.000000, -21.000000) "
          >
            <use fill="black" fill-opacity="1" filter="url(#filter-3)" xlink:href="#path-2" />
            <rect
              stroke="#FFFFFF"
              stroke-width="2"
              fill="url(#linearGradient-1)"
              fill-rule="evenodd"
              x="-1"
              y="-1"
              width="44"
              height="44"
              rx="20.9999987"
            />
          </g>
          <g id="Group-6" transform="translate(10.000000, 10.000000)" fill="#FFFFFF">
            <path
              d="M21.4375059,18.5152657 L14.9191056,11.9968655 C14.1656556,13.1686655 13.1687555,14.1657156 11.9968055,14.9190156 L18.5153557,21.4374159 C19.3225058,22.2447159 20.6317058,22.2447159 21.4375059,21.4374159 C22.2448059,20.6316158 22.2448059,19.3225658 21.4375059,18.5152657"
              id="Fill-1"
            />
            <path
              d="M8.26606533,14.4657006 C4.84741519,14.4657006 2.06656508,11.6847005 2.06656508,8.26605033 C2.06656508,4.84740019 4.84741519,2.06655008 8.26606533,2.06655008 C11.6847155,2.06655008 14.4657156,4.84740019 14.4657156,8.26605033 C14.4657156,11.6847005 11.6847155,14.4657006 8.26606533,14.4657006 M16.5321157,8.26605033 C16.5321157,3.70110015 12.8310155,0 8.26606533,0 C3.70111515,0 1.50000006e-05,3.70110015 1.50000006e-05,8.26605033 C1.50000006e-05,12.8310005 3.70111515,16.5321007 8.26606533,16.5321007 C12.8310155,16.5321007 16.5321157,12.8310005 16.5321157,8.26605033"
              id="Fill-4"
            />
          </g>
        </g>
        <g
          id="Group"
          @click="onClickPin('tourA')"
          class="stadiumToursPin"
          :class="{'active': pinState.tourA}"
          transform="translate(155.000000, 571.000000)"
        >
          <g
            id="Rectangle"
            transform="translate(21.000000, 21.000000) scale(-1, 1) translate(-21.000000, -21.000000) "
          >
            <use fill="black" fill-opacity="1" filter="url(#filter-6)" xlink:href="#path-5" />
            <rect
              stroke="#FFFFFF"
              stroke-width="2"
              fill="url(#linearGradient-4)"
              fill-rule="evenodd"
              x="-1"
              y="-1"
              width="44"
              height="44"
              rx="20.9999987"
            />
          </g>
          <g id="tour-stop-icon" transform="translate(10.000000, 10.000000)" fill="#FFFFFF">
            <path
              d="M21.4375059,18.5152657 L14.9191056,11.9968655 C14.1656556,13.1686655 13.1687555,14.1657156 11.9968055,14.9190156 L18.5153557,21.4374159 C19.3225058,22.2447159 20.6317058,22.2447159 21.4375059,21.4374159 C22.2448059,20.6316158 22.2448059,19.3225658 21.4375059,18.5152657"
              id="Fill-1"
            />
            <path
              d="M8.26606533,14.4657006 C4.84741519,14.4657006 2.06656508,11.6847005 2.06656508,8.26605033 C2.06656508,4.84740019 4.84741519,2.06655008 8.26606533,2.06655008 C11.6847155,2.06655008 14.4657156,4.84740019 14.4657156,8.26605033 C14.4657156,11.6847005 11.6847155,14.4657006 8.26606533,14.4657006 M16.5321157,8.26605033 C16.5321157,3.70110015 12.8310155,0 8.26606533,0 C3.70111515,0 1.50000006e-05,3.70110015 1.50000006e-05,8.26605033 C1.50000006e-05,12.8310005 3.70111515,16.5321007 8.26606533,16.5321007 C12.8310155,16.5321007 16.5321157,12.8310005 16.5321157,8.26605033"
              id="Fill-4"
            />
          </g>
        </g>
        <g
          id="Group"
          @click="onClickPin('tourD')"
          class="stadiumToursPin"
          :class="{'active': pinState.tourD}"
          transform="translate(469.000000, 407.000000)"
        >
          <g
            id="Rectangle"
            transform="translate(21.000000, 21.000000) scale(-1, 1) translate(-21.000000, -21.000000) "
          >
            <use fill="black" fill-opacity="1" filter="url(#filter-8)" xlink:href="#path-7" />
            <rect
              stroke="#FFFFFF"
              stroke-width="2"
              fill="url(#linearGradient-1)"
              fill-rule="evenodd"
              x="-1"
              y="-1"
              width="44"
              height="44"
              rx="20.9999987"
            />
          </g>
          <g id="Group-6" transform="translate(10.000000, 10.000000)" fill="#FFFFFF">
            <path
              d="M21.4375059,18.5152657 L14.9191056,11.9968655 C14.1656556,13.1686655 13.1687555,14.1657156 11.9968055,14.9190156 L18.5153557,21.4374159 C19.3225058,22.2447159 20.6317058,22.2447159 21.4375059,21.4374159 C22.2448059,20.6316158 22.2448059,19.3225658 21.4375059,18.5152657"
              id="Fill-1"
            />
            <path
              d="M8.26606533,14.4657006 C4.84741519,14.4657006 2.06656508,11.6847005 2.06656508,8.26605033 C2.06656508,4.84740019 4.84741519,2.06655008 8.26606533,2.06655008 C11.6847155,2.06655008 14.4657156,4.84740019 14.4657156,8.26605033 C14.4657156,11.6847005 11.6847155,14.4657006 8.26606533,14.4657006 M16.5321157,8.26605033 C16.5321157,3.70110015 12.8310155,0 8.26606533,0 C3.70111515,0 1.50000006e-05,3.70110015 1.50000006e-05,8.26605033 C1.50000006e-05,12.8310005 3.70111515,16.5321007 8.26606533,16.5321007 C12.8310155,16.5321007 16.5321157,12.8310005 16.5321157,8.26605033"
              id="Fill-4"
            />
          </g>
        </g>
        <g
          id="Group"
          @click="onClickPin('tourC')"
          class="stadiumToursPin"
          :class="{'active': pinState.tourC}"
          transform="translate(724.000000, 601.000000)"
        >
          <g
            id="Rectangle"
            transform="translate(21.000000, 21.000000) scale(-1, 1) translate(-21.000000, -21.000000) "
          >
            <use fill="black" fill-opacity="1" filter="url(#filter-10)" xlink:href="#path-9" />
            <rect
              stroke="#FFFFFF"
              stroke-width="2"
              fill="url(#linearGradient-1)"
              fill-rule="evenodd"
              x="-1"
              y="-1"
              width="44"
              height="44"
              rx="20.9999987"
            />
          </g>
          <g id="Group-6" transform="translate(10.000000, 10.000000)" fill="#FFFFFF">
            <path
              d="M21.4375059,18.5152657 L14.9191056,11.9968655 C14.1656556,13.1686655 13.1687555,14.1657156 11.9968055,14.9190156 L18.5153557,21.4374159 C19.3225058,22.2447159 20.6317058,22.2447159 21.4375059,21.4374159 C22.2448059,20.6316158 22.2448059,19.3225658 21.4375059,18.5152657"
              id="Fill-1"
            />
            <path
              d="M8.26606533,14.4657006 C4.84741519,14.4657006 2.06656508,11.6847005 2.06656508,8.26605033 C2.06656508,4.84740019 4.84741519,2.06655008 8.26606533,2.06655008 C11.6847155,2.06655008 14.4657156,4.84740019 14.4657156,8.26605033 C14.4657156,11.6847005 11.6847155,14.4657006 8.26606533,14.4657006 M16.5321157,8.26605033 C16.5321157,3.70110015 12.8310155,0 8.26606533,0 C3.70111515,0 1.50000006e-05,3.70110015 1.50000006e-05,8.26605033 C1.50000006e-05,12.8310005 3.70111515,16.5321007 8.26606533,16.5321007 C12.8310155,16.5321007 16.5321157,12.8310005 16.5321157,8.26605033"
              id="Fill-4"
            />
          </g>
        </g>
        <g id="dots" fill="#000000">
          <rect id="Rectangle" x="1599" y="899" width="1" height="1" />
          <rect id="Rectangle" x="0" y="0" width="1" height="1" />
          <rect id="Rectangle" x="1599" y="0" width="1" height="1" />
          <rect id="Rectangle" x="0" y="899" width="1" height="1" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "StadiumToursPins",
  props: ["pinState"],
  methods: {
    onClickPin(pin) {
      Object.keys(this.pinState).forEach((key, index) => {
        if (key === pin) {
          this.pinState[key] = true;
        } else {
          this.pinState[key] = false;
        }
      });
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.stadiumToursPin {
  cursor: pointer;
  rect {
    fill: #1f1f1f;
  }
  &.active {
    rect{
        fill: #937900;
    }
  }
}
</style>